import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        width: 3.2rem;
        height: 3.2rem;
        border: 0.2rem solid ${theme.palette.background.body};
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
    `}
`;
