import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding-top: ${theme.gap.large};
        padding-bottom: ${theme.gap.large};
        background-image: url(/images/faq/background.png);
        background-repeat: no-repeat;
        background-position: 50% 10rem;

        @media ${theme.breakpoints.md.min} {
            padding-top: ${theme.gap.xxlarge};
            padding-bottom: 15rem;
        }

        @media ${theme.breakpoints.xl.max} {
            background-size: 130% auto;
        }
    `}
`;

export const Header = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.gap.small};

        @media ${theme.breakpoints.md.min} {
            margin-bottom: ${theme.gap.xxlarge};
        }
    `}
`;

export const Inner = styled.div`
    max-width: 90rem;
    margin: 0 auto;
`;

export const More = styled.div`
    ${({ theme }) => css`
        padding-top: ${theme.gap.xlarge};
        text-align: center;
    `}
`;
