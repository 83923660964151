import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        padding: 1rem 0 6rem 0;

        @media ${theme.breakpoints.lg.min} {
            padding: 16rem 0 12rem 0;
        }
    `,
);

export const Inner = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-bottom: 5rem;

        @media ${theme.breakpoints.vl.min} {
            display: grid;
            grid-template-columns: 1.5fr 2fr;
            gap: 8rem;
            margin-bottom: 12rem;
        }
    `,
);

export const Content = styled.div(
    ({ theme }) => css`
        flex: 1;
        display: flex;
        flex-direction: column;

        @media ${theme.breakpoints.md.max} {
            width: 100%;
        }
    `,
);

export const Buttons = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media ${theme.breakpoints.md.min} {
            align-items: center;
            flex-direction: row;
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        flex: 2;
        overflow: hidden;
        border-radius: ${theme.radius.large};
        max-width: 681px;

        @media ${theme.breakpoints.vl.max} {
            margin-bottom: 4rem;
            max-width: unset;
        }

        @media ${theme.breakpoints.md.max} {
            width: 100%;
        }
    `}
`;
