import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['marginTop', 'marginBottom'].includes(prop),
})<{
    marginTop: GapSizeType;
    marginBottom: GapSizeType;
}>`
    ${({ theme, marginTop, marginBottom }) => css`
        position: relative;
        margin-top: ${theme.gap[marginTop]};
        margin-bottom: ${theme.gap[marginBottom]};
        border-radius: ${theme.radius.large};

        iframe {
            display: block;
            aspect-ratio: 16 / 9;
            width: 100%;
            height: 100%;
            border: 0;
            border-radius: ${theme.radius.large};
        }
    `}
`;

export const Placeholder = styled.div.withConfig({
    shouldForwardProp: (prop) => !['image'].includes(prop),
})<{
    image: string;
}>`
    ${({ theme, image }) => css`
        aspect-ratio: 16 / 9;
        background-image: url(${image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: ${theme.palette.common.textInverse};
        z-index: 1;
        cursor: pointer;
        border-radius: ${theme.radius.large};

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background-color: ${theme.palette.color.secondary.main};
            opacity: 0.4;
            z-index: 1;
            border-radius: ${theme.radius.large};
            transition: opacity ${theme.base.transition};
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 6rem;
            height: 6rem;
            color: ${theme.palette.common.textInverse};
            z-index: 2;
            transition: color ${theme.base.transition};
        }

        &:hover {
            &:after {
                opacity: 0;
            }

            i {
                color: ${theme.palette.color.primary.main};
            }
        }
    `}
`;
