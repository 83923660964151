import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Inner, More, Header } from './faq-top.styled';
import { FaqList } from '../../../../components/web/faq/list/faq-list';
import { Container } from '../../../../components/web/container/container';
import { Headline } from '../../../../components/app/headline/headline';
import { Button } from '../../../../components/app/form/button/button';
import { IconNext } from '../../../../components/app/icons/next';

export const FaqTop: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <Header>
                    <Headline level="1" tag="h2" gap="large" align="center">
                        {t('pages.faq.title')}
                    </Headline>
                </Header>
                <Inner>
                    <FaqList limit={3} />
                    <More>
                        <Button href="/faq" icon={<IconNext />} variant="primary" variation="outlined">
                            {t('pages.faq.more')}
                        </Button>
                    </More>
                </Inner>
            </Container>
        </Wrapper>
    );
};
