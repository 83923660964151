import * as React from 'react';
import { Container } from '../../../components/web/container/container';
import { Wrapper, TitleGrey, Title } from './cta.styled';

interface CtaPropsInterface {
    title: string;
    titleGrey?: string;
    small?: boolean;
}

export const Cta: React.FC<CtaPropsInterface> = ({ title, titleGrey, small = false }) => {
    return (
        <Wrapper small={small}>
            <Container>
                <TitleGrey>{titleGrey}</TitleGrey>
                <Title>{title}</Title>
            </Container>
        </Wrapper>
    );
};
