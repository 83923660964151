import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 0;

    @media (max-width: 991px) {
        flex-direction: column;
    }
`;

export const Content = styled.div`
    padding: 2rem 8.8rem;
    width: 50%;

    @media (max-width: 991px) {
        width: 100%;
        order: 1;
    }

    @media (max-width: 768px) {
        padding: 2rem 0;
    }
`;

export const Title = styled.div(
    ({ theme }) => css`
        font-size: ${theme.headline.size.h1.fontSize};
        line-height: ${theme.headline.size.h1.lineHeight};
        font-weight: 700;
        color: ${theme.palette.color.secondary.main};
        margin-bottom: 2.4rem;

        @media (max-width: 768px) {
            font-size: 2.8rem;
            line-height: 3.3rem;
            margin-bottom: 1.6rem;
        }
    `,
);

export const Text = styled.p(
    ({ theme }) => css`
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: ${theme.palette.color.gray.main};

        @media (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 2.5rem;
        }

        @media (max-width: 480px) {
            max-width: 26.5rem;
        }
    `,
);

export const ImageWrapper = styled.div`
    display: block;
    width: 50%;

    @media (max-width: 991px) {
        width: 100%;
        order: 2;
    }
`;
