import React from 'react';
import { Wrapper } from './responsive.styled';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

interface IResponsiveProps {
    breakpoint: BreakpointSizeType;
    children: React.ReactElement;
    type?: 'max' | 'min';
}

export const Responsive: React.FC<IResponsiveProps> = ({
    children,
    breakpoint,
    type = 'min',
    ...props
}: IResponsiveProps) => {
    return (
        <Wrapper breakpoint={breakpoint} type={type}>
            {React.cloneElement(children, { ...props })}
        </Wrapper>
    );
};
