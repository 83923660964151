import * as React from 'react';
import { Wrapper } from './hero-text.styled';

type HeroTextProps = {
    children: React.ReactNode | Array<React.ReactNode>;
};

export const HeroText: React.FC<HeroTextProps> = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};
