import Image from 'next/image';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Wrapper, Content, Title, Text, ImageWrapper } from './feature.styled';

interface FeaturePropsInterface {
    title: string;
    text?: string;
    imageSource?: string;
    imagePosition?: 'left' | 'right';
}

export const Feature: React.FC<FeaturePropsInterface> = ({ title, text, imageSource, imagePosition }) => {
    const { t } = useTranslation();

    const Headline = () => <Trans i18nKey={title} components={{ br: <br /> }} />;
    const alt = t(title).replace(/<\/?[^>]+(>|$)/g, '');

    const ContentBlock = (
        <Content>
            <Title>
                <Headline />
            </Title>
            <Text>{text}</Text>
        </Content>
    );

    const ImageBlock = (
        <ImageWrapper>
            <Image
                src={`/images/${imageSource}`}
                alt={alt}
                width="4"
                height="3"
                layout="responsive"
                objectFit="contain"
            />
        </ImageWrapper>
    );

    return (
        <Wrapper>
            {imagePosition === 'right' && (
                <>
                    {ContentBlock}
                    {ImageBlock}
                </>
            )}
            {imagePosition === 'left' && (
                <>
                    {ImageBlock}
                    {ContentBlock}
                </>
            )}
        </Wrapper>
    );
};
