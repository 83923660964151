import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 12rem;
    padding-bottom: 4rem;

    @media (max-width: 768px) {
        padding-top: 8rem;
    }
`;

export const Header = styled.div`
    max-width: 80rem;
    margin: 0 auto 8rem;
`;
