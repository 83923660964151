import styled, { css } from 'styled-components';

export const Wrapper = styled.p(
    ({ theme }) => css`
        font-size: 2rem;
        line-height: 3.2rem;
        color: ${theme.palette.color.white.main};
        margin: 0 0 4.8rem 0;

        strong {
            font-weight: 900;
        }
    `,
);
