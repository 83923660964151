import styled, { css } from 'styled-components';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['breakpoint', 'type', 'fullHeight'].includes(prop),
})<{
    breakpoint: BreakpointSizeType;
    type: 'max' | 'min';
    fullHeight?: boolean;
}>(
    ({ theme, breakpoint, type }) => css`
        @media ${theme.breakpoints[breakpoint][type === 'min' ? 'max' : 'min']} {
            display: none;
        }
    `,
);
