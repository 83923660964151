import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Wrapper, Avatars, Content, Inner } from './join-us.styled';
import { JoinUsAvatar } from './avatar/join-us-avatar';
import { Button } from '../../app/form/button/button';
import { useRootStore } from '../../../providers/root-store-provider';

export const JoinUs: React.FC = observer(() => {
    const rootStore = useRootStore();
    const { authStore, homepageStore } = rootStore;

    return (
        <Wrapper>
            <Inner>
                <Avatars>
                    <JoinUsAvatar src="person-1.png" />
                    <JoinUsAvatar src="person-2.png" />
                    <JoinUsAvatar src="person-3.png" />
                    <JoinUsAvatar src="person-4.png" />
                    <JoinUsAvatar src="person-5.png" />
                    <JoinUsAvatar src="person-6.png" />
                </Avatars>
                <Content>
                    <Trans
                        i18nKey={`blocks.joinUs.text`}
                        values={{ countOfUsers: `${homepageStore.roundedCount}+` }}
                        components={{ strong: <strong /> }}
                    />
                </Content>
            </Inner>
            <Button
                onClick={() => authStore.authDialogStore.setSignupActive()}
                size="large"
                variant="positive"
                gradient
                shadow
            >
                Registrovat se
            </Button>
        </Wrapper>
    );
});
