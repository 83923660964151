import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './homepage-video.styled';
import { VideoYoutube } from '../../../components/web/video/youtube/video-youtube';

export const HomepageVideo: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <VideoYoutube
                placeholder={'/images/homepage/video-placeholder.png'}
                videoId="a7kLcADMtNY"
                title={t('pages.configurators.demo.sample')}
            />
        </Wrapper>
    );
};
