import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Wrapper, Header } from './feature-list.styled';
import { Container } from '../../../components/web/container/container';
import { Headline } from '../../../components/app/headline/headline';
import { Text } from '../../../components/app/text/text';
import { Feature } from '../../../components/web/feature/feature';
import { JoinUs } from '../../../components/web/join-us/join-us';
import { HomepageVideo } from '../homepage-video/homepage-video';

export const FeatureList: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Wrapper id="featureList">
            <Container>
                <Header>
                    <Headline level="1" tag="h2" align="center">
                        {t('pages.homepage.features.title')}
                    </Headline>
                    <Text size="xlarge" align="center">
                        <Trans i18nKey="pages.homepage.features.subtitle" components={{ strong: <strong /> }} />
                    </Text>
                </Header>

                <HomepageVideo />

                <JoinUs />

                <Feature
                    title="pages.homepage.features.feature1.title"
                    text={t('pages.homepage.features.feature1.subtitle')}
                    imageSource="features/feature-1.jpg"
                    imagePosition="left"
                />
                <Feature
                    title="pages.homepage.features.feature2.title"
                    text={t('pages.homepage.features.feature2.subtitle')}
                    imageSource="features/feature-2.jpg"
                    imagePosition="right"
                />
                <Feature
                    title="pages.homepage.features.feature3.title"
                    text={t('pages.homepage.features.feature3.subtitle')}
                    imageSource="features/feature-3.jpg"
                    imagePosition="left"
                />
                <Feature
                    title="pages.homepage.features.feature4.title"
                    text={t('pages.homepage.features.feature4.subtitle')}
                    imageSource="features/feature-4.jpg"
                    imagePosition="right"
                />
            </Container>
        </Wrapper>
    );
};
