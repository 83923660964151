import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    justify-content: center;
    margin-bottom: 3rem;
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        box-shadow: inset ${theme.shadow.medium};
        background-color: ${theme.palette.background.body};
        padding: ${theme.gap.small};
        border-radius: ${theme.radius.medium};
        border: 0.1rem solid ${theme.palette.common.border};
        flex-direction: column;
        text-align: center;
        cursor: pointer;

        @media ${theme.breakpoints.md.min} {
            flex-direction: row;
            border-radius: 20rem;
            gap: 2rem;
            padding: 1rem 2.2rem 1rem 2.6rem;
        }
    `}
`;

export const Avatars = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin-left: -1.2rem;
    }
`;

export const Content = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.color.default.dark};
        font-size: ${theme.text.size.xlarge.fontSize};
        line-height: ${theme.text.size.xlarge.lineHeight};
        font-weight: 700;

        strong {
            font-weight: 900;
            color: ${theme.palette.color.positive.darken};
        }
    `}
`;
