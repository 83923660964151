import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
        gap: 5rem;
        margin: auto;
        max-width: 100vw;
    `,
);

export const Marquee = styled.div(
    ({ theme }) => css`
        display: flex;
        overflow: hidden;
        user-select: none;
        gap: 5rem;
        mask-image: linear-gradient(
            var(--mask-direction, to right),
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 20%,
            hsl(0 0% 0% / 1) 80%,
            hsl(0 0% 0% / 0)
        );

        @media ${theme.breakpoints.lg.min} {
            gap: 10rem;
        }
    `,
);

export const MarqueeGroup = styled.div(
    ({ theme }) => css`
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 5rem;
        min-width: 100%;
        animation: scroll-x 60s linear infinite;

        @media ${theme.breakpoints.lg.min} {
            gap: 10rem;
        }

        @keyframes scroll-x {
            from {
                transform: translateX(0);
            }
            to {
                transform: translateX(calc(-100% - 10rem));
            }
        }

        @keyframes scroll-y {
            from {
                transform: translateY(0);
            }
            to {
                transform: translateY(calc(-100% - 10rem));
            }
        }

        svg {
            display: grid;
            place-items: center;
            border-radius: 0.5rem;
            height: 1.5rem;
            width: auto;
            position: relative;

            &.interactive-brokers {
                top: -0.5rem;
                height: 2.5rem;
            }

            &.etoro {
                height: 2rem;
                top: -0.5rem;
            }

            &.xtb {
                height: 1.5rem;
            }
        }
        @media ${theme.breakpoints.lg.min} {
            svg {
                height: 3rem;

                &.interactive-brokers {
                    height: 3.5rem;
                }

                &.etoro {
                    height: 3.5rem;
                }

                &.xtb {
                    height: 3.5rem;
                }
            }
        }
    `,
);
