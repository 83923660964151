import * as React from 'react';
import { Wrapper } from './hero-headline.styled';

type HeroHeadlineProps = {
    children: React.ReactNode | Array<React.ReactNode>;
};

export const HeroHeadline: React.FC<HeroHeadlineProps> = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};
