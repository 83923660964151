import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        color: ${theme.palette.color.white.main};
        font-size: 4rem;
        line-height: 4.5rem;
        margin-bottom: 3rem;

        @media ${theme.breakpoints.lg.min} {
            font-size: 5.4rem;
            line-height: 6.1rem;
        }

        span {
            color: ${theme.palette.color.white.main};
            font-weight: 900;
        }
    `,
);
