import React from 'react';
import Image from 'next/image';
import { Wrapper } from './join-us-avatar.styled';

type JoinUsAvatarProps = {
    src: string;
};

export const JoinUsAvatar: React.FC<JoinUsAvatarProps> = ({ src }) => {
    return (
        <Wrapper>
            <Image src={`/images/join-us/${src}`} width="32" height="32" />
        </Wrapper>
    );
};
